<template>
    <div v-if="show">
        <div class="card" v-if="operation !== 'detail'">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template v-if="operation === null">{{$t('title.yearlyForecasts')}}</template>
                                    <template v-else>{{ $t(operationTitle) }}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <div v-if="operation === null">
                                <div class="mt-3">
                                    <!-- Using components -->
                                    <b-input-group class="mt-3">
                                        <b-form-input type="search" class="form-control form-control-sm"
                                                      :placeholder="$t('input.whatAreYouLookingFor')"
                                                      v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                      variant="outline-info"
                                                      @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('annualforecastsview')">
                                                <i class="fa fa-filter"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.resetList')"
                                                      variant="outline-info"
                                                      @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                <i class="fa fa-refresh"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                            <div v-else>
                                <b-button variant="warning" size="sm" class="mt-3"
                                          @click="handleOperationClose()"
                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                    <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                </b-button>
                            </div>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div><!-- /.card-header -->
            <div class="card-body">
                <div class="annual-forecasts-table">
                    <b-table hover responsive
                             ref="table"
                             :busy="listingLoading"
                             :items="dataSource"
                             :fields="columns"
                             :sort-by.sync="sortField"
                             @sort-changed="handleSortChange">
                        <template v-slot:cell(client)="record">
                            {{record.item.client ? record.item.client.company_name : ''}}
                        </template>
                        <template v-slot:cell(action)="record">
                            <a @click="setOperation('detail', record.item.id)" class="ml-1"
                               :title="$t('button.title.itemDetails')" v-if="$global.hasPermission('annualforecastsview')"
                               v-b-tooltip.hover>
                                <i class="icmn-info"></i>
                            </a>
                        </template>
                    </b-table><!-- /.b-table -->
                    <div class="clearfix">
                        <div class="float-left">
                            <b-form-select v-model="pagination.perPage" :options="[10, 50, 100]"></b-form-select>
                        </div>
                        <div class="float-right">
                            <b-pagination
                                v-model="pagination.current"
                                :total-rows="pagination.total"
                                :per-page="pagination.perPage"
                                :first-text="$t('paginations.first')"
                                :prev-text="$t('paginations.prev')"
                                :next-text="$t('paginations.next')"
                                :last-text="$t('paginations.last')"
                            ></b-pagination>
                        </div><!-- /.pull-right -->
                    </div><!-- /.clearfix -->
                </div><!-- /.annual-forecasts-table -->
                <div class="filter-container">
                    <a-drawer
                        placement="left"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="filters.visible = !filters.visible"
                        :visible="!operation && filters.visible"
                        :zIndex="10"
                        :title="$t('title.advanceFilters')"
                    >
                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.clients')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.clients"
                                            placeholder=""
                                            v-model="filters.clients"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.fromAddedDate')"
                                        label-for="fromDate">
                                        <b-form-datepicker placeholder="" id="fromDate" v-model="filters.from_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.toAddedDate')"
                                        label-for="toDate">
                                        <b-form-datepicker placeholder="" id="toDate" v-model="filters.to_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                    {{$t('button.close')}}
                                </b-button>
                                <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                    {{$t('button.reset')}}
                                </b-button>
                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                    {{$t('button.apply')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.filter-container -->
            </div><!-- /.card-body-->
        </div><!-- /.card -->

        <div class="clients-detail-operation" v-if="operation !== null && operation === 'detail'">
            <detail :id="listQueryParams.oToken" :handle-back="handleOperationClose"></detail>
        </div><!--/.clients-details-operation-->
    </div>
</template>
<script>
import ListingMixin from '../../util/ListingMixin'
import Error from '../../util/Error'
import {mapState} from 'vuex'
import Datepicker from 'vuejs-datepicker'
import {request} from '../../util/Request'
import Treeselect from '@riophae/vue-treeselect'
import _ from 'lodash'
import Detail from "./detail";

const FORM_STATE = {
    title: null,
    year: null,
    annual_forecast_details: [],
    _method: 'post',
};

const FILTER_STATE = {
    visible: false,
    from_date: null,
    to_date: null,
    clients: [],
};

const COLUMN_DEFINITION = (self) => [
    {
        label: self.$t('column.client'),
        key: 'client',
        sortable: true,
        sortKey: 'client',
    },
    {
        label: self.$t('column.title'),
        key: 'title',
        sortable: true,
        sortKey: 'title',
    },
    {
        label: self.$t('column.year'),
        key: 'year',
        sortable: true,
        sortKey: 'year',
    },
    (self.$global.hasAnyPermission(['annualforecastsview'])
        ? {
            label: self.$t('column.action'),
            class: 'text-right',
            key: 'action',
            width: 150,
        } : {}),
];

export default {
    mixins: [ListingMixin],
    components: {
        Datepicker,
        Treeselect,
        Detail
    },
    data() {
        return {
            operationTitle: 'title.yearlyForecasts',
            formFields: {...FORM_STATE},
            filters: {...FILTER_STATE},
            annual_forecast_details: [],
            client_brand_models: [],
            listUrl: '/annual/forecasts',
            columns: COLUMN_DEFINITION(this),
            show: true,
            dropdowns: {
                clients: []
            }
        }
    },
    mounted() {
        this.getClients()
        if (this.$route.query && this.$route.query.operation !== 'detail' && this.$route.query.oToken) {
            this.handleEditClick(this.$route.query.oToken);
        }

        this.$title = this.$t('topBar.navigations.modules.yearlyForecasts')
    },
    methods: {
        setOperation(operation = 'add', operationToken = null) {
            this.operationTitle = `title.${operation}YearlyForecast`;

            this.$router.replace({
                query: Object.assign({},
                    this.$route.query,
                    {
                        ...this.listQueryParams,
                        operation: operation,
                        oToken: operationToken,
                    },
                ),
            }).then(() => {
            }).catch(() => {
            })
        },
        async getClients() {
            try {
                const response = await request({
                    url: '/dropdowns/clients',
                    method: "post"
                })

                const {data} = response
                this.dropdowns.clients = data

            } catch (e) {
                this.dropdowns.clients = []
            }
        },
        hasListAccess() {
            return this.$global.hasPermission('annualforecastsview')
        },
        getExtraParams() {
            return {
                filters: {
                    ...this.filters,
                    from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                },
            }
        },
        handleResetFilterClick() {
            this.filters = {...FILTER_STATE}
            this.isFilterApplied = 'reset'
            this.handleResetClick()
            this.loadList(this.listQueryParams)
        },
        afterCloseOperation() {
            this.formFields = {...FORM_STATE};
            this.annual_forecast_details.length = 0;
            this.client_brand_models.length = 0;
        },
    },
    computed: {
        ...mapState([
            'global',
            'settings'
        ]),
    },
}
</script>
<style lang="scss">
.min-24px {
    min-height: 20px;
}

@media screen and (max-width: 768px) {
    .ant-drawer-content-wrapper {
        width: 98% !important;
    }

    .min-24px {
        min-height:24px !important;
    }
}
</style>

